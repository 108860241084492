<template>
  <div class="bgStyle">
    <div class="title">数据字典</div>
    <div>
      <a-space size="8">
        <a-input-search
          placeholder="请输入查询内容"
          style="width: 200px"
          @search="onSearch"
        />
        <a-button type="primary" @click="whenAddRow">新建数据字典</a-button>
      </a-space>
    </div>
    <a-table
      :columns="columns"
      :data-source="datasource"
      :loading="loading"
      class="table"
      rowKey="id"
      :pagination="pagination"
      @change="handleTableChange"
    >
      <template slot="action" slot-scope="text, record">
        <a @click="whenViewRow(record)">查看</a>
        <a-divider type="vertical" />
        <a @click="whenEditRow(record)">修改</a>
        <a-divider type="vertical" />
        <a @click="whenDeleteRow(record)">删除</a>
        <a-divider type="vertical" />
      </template>
      <template slot="checkbox" slot-scope="text, record">
        <a-checkbox :checked="record.hasChild"></a-checkbox>
      </template>
    </a-table>
    <code-add
      ref="add"
      :visible="addCtrl.visible"
      :readonly="addCtrl.readonly"
      :form="this.addCtrl.form"
      @handleTableChange="handleTableChange"
      @getCodes="getCodes"
      @submited="
        () => {
          this.addCtrl.visible = false;
        }
      "
      @cancel="
        () => {
          this.addCtrl.visible = false;
        }
      "
    ></code-add>
  </div>
</template>
<script>
import CodeAdd from "./components/CodeAdd.vue";
import saasAdmin from "@/api/saasAdmin.js";
const { getCodeList, deleteCode } = saasAdmin.code;
/**数据字典列表列配置 */
const columns = [
  {
    title: "编号",
    dataIndex: "codeKeyPath",
    ellipsis: true,
  },
  {
    title: "名称",
    dataIndex: "codeName",
    ellipsis: true,
  },
  {
    title: "顺序",
    dataIndex: "showOrder",
  },
  {
    title: "值",
    dataIndex: "value",
    ellipsis: true,
  },
  {
    title: "描述",
    dataIndex: "description",
    ellipsis: true,
  },
  {
    title: "是否添加子项目",
    dataIndex: "hasChild",
    align: "center",
    scopedSlots: { customRender: "checkbox" },
  },
  {
    title: "父编号",
    dataIndex: "codeKey",
    ellipsis: true,
  },
  {
    title: "操作",
    dataIndex: "action",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];

export default {
  data() {
    return {
      datasource: [], //数据字典数据源
      columns, //配置列
      /**列表分页参数设置 */
      pagination: {
        pageSize: 10,
        total: 0,
        current: 1,
      },
      loading: false,
      addCtrl: {
        visible: false,
        readonly: false,
        form: {},
      },
    };
  },
  components: { CodeAdd },
  /**组件生命周期编译后事件 */
  mounted() {
    this.getCodes();
  },
  methods: {
    /**
     * 删除标签单击事件
     * @param {obj} row [当前行]
     */
    whenDeleteRow(row) {
      deleteCode(row.id)
        .then(() => {
          this.$message.success("删除成功");
          var parm = {
            skipCount: (this.pagination.current - 1) * this.pagination.pageSize,
          };
          this.getCodes(parm);
        })
        .catch(() => {
          this.$message.error("删除失败");
        });
    },
    /**
     * 修改标签单击事件
     * @param {obj} row [当前行]
     */
    whenEditRow(row) {
      var currentRow = JSON.parse(JSON.stringify(row));
      this.addCtrl.form = currentRow;
      this.addCtrl.readonly = false;
      this.addCtrl.visible = true;
    },
    /**
     * 查询标签单击事件
     * @param {obj} row [当前行]
     */
    whenViewRow(row) {
      var entity = JSON.parse(JSON.stringify(row));
      this.addCtrl.form = entity;
      this.addCtrl.readonly = true;
      this.addCtrl.visible = true;
    },
    /**
     * 添加按钮单击事件
     * @param {obj} row [当前行]
     */
    whenAddRow() {
      this.addCtrl.form = {};
      this.addCtrl.readonly = false;
      this.addCtrl.visible = true;
    },
    /**
     * 数据字典查询方法
     * @param {obj} parm [查询参数]
     */
    getCodes(parm) {
      this.loading = true;
      getCodeList(parm)
        .then((res) => {
          this.pagination.total = res.totalCount;
          this.datasource = res.items;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.$message.error("数据字典查询异常");
        });
    },
    /**
     * 查询输入框搜索按钮
     * @param {string} value [输入框文本]]
     */
    onSearch(value) {
      var parm = {
        filter: value,
      };
      this.getCodes(parm);
    },
    /**
     * 列表切换分页事件
     * @param {obj} pagination [当前分页参数]]
     */
    handleTableChange(pagination) {
      const pager = { ...this.pagination };
      pager.current = pagination ? pagination.current : pager.current;
      this.pagination = pager;
      var parm = {
        skipCount: (this.pagination.current - 1) * this.pagination.pageSize,
      };
      this.getCodes(parm);
    },
  },
};
</script>
<style lang="scss" scoped>
.table {
  margin-top: 10px;
}
.title {
  font-size: 14px;
  font-weight: 600;
  padding: 10px 0;
}
</style>
