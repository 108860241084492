<template>
  <a-modal
    title="数据字典"
    :visible="visible"
    @ok="whenSubmit"
    @cancel="$emit('cancel')"
    :destroyOnClose="true"
    width="700px"
    :okText="'确定'"
  >
    <a-form-model
      class="formInfo"
      ref="registerForm"
      layout="horizontal"
      :rules="rules"
      :model="registerForm"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 20 }"
      labelAlign="left"
    >
      <a-form-model-item label="名称" prop="codeName">
        <a-input
          type="text"
          placeholder="名称"
          v-model="registerForm.codeName"
          autocomplete="off"
          :disabled="readonly"
        />
      </a-form-model-item>
      <a-form-model-item label="唯一键" prop="codeKey">
        <a-input
          type="text"
          placeholder="唯一键"
          v-model="registerForm.codeKey"
          autocomplete="off"
          :disabled="readonly"
          @change="whenChangeCode"
        />
      </a-form-model-item>
      <a-form-model-item label="唯一标识" prop="codeKeyPath">
        <a-input
          type="text"
          placeholder="唯一标识"
          v-model="registerForm.codeKeyPath"
          autocomplete="off"
          :disabled="true"
        />
      </a-form-model-item>
      <a-form-model-item label="顺序" prop="showOrder">
        <a-input
          type="text"
          placeholder="顺序"
          v-model="registerForm.showOrder"
          autocomplete="off"
          :disabled="readonly"
        />
      </a-form-model-item>
      <a-form-model-item label="值" prop="value">
        <a-input
          type="text"
          placeholder="值"
          v-model="registerForm.value"
          autocomplete="off"
          :disabled="readonly"
        />
      </a-form-model-item>
      <a-form-model-item label="描述" prop="description">
        <a-input
          type="textarea"
          placeholder="描述"
          v-model="registerForm.description"
          autocomplete="off"
          :disabled="readonly"
        />
      </a-form-model-item>
      <a-form-model-item label="是否添加子项目" prop="hasChild">
        <a-checkbox
          @change="onChange"
          :checked="hasChild"
          :disabled="readonly"
        ></a-checkbox>
      </a-form-model-item>
      <a-form-model-item label="父编号" prop="parentCodeId">
        <a-select
          show-search
          mode="default"
          notFoundContent="不存在父编号"
          placeholder="请选择父编号"
          :key="registerForm.parentCodeId"
          :defaultValue="registerForm.parentCodeId"
          :default-active-first-option="false"
          :show-arrow="false"
          :filter-option="false"
          :disabled="readonly"
          @select="whenChangeParent"
          @search="handleSearch"
          allowClear
        >
          <a-select-option
            v-for="parentCode in parentCodeList"
            :key="parentCode.id"
            :value="parentCode.id"
            :title="`${parentCode.name}-${parentCode.code}`"
          >
            {{ `${parentCode.name}-${parentCode.code}` }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import saasAdmin from "@/api/saasAdmin.js";
const { updateCode, createCode, getDowndownAsync } = saasAdmin.code;
export default {
  name: "AddCode",
  components: {},
  props: {
    visible: { type: Boolean, default: () => false },
    readonly: { type: Boolean, default: () => false },
    form: { type: Object, default: () => {} },
  },
  /** 组件生命周期组件创建后事件 */
  created() {
    this.handleSearch("");
  },
  data() {
    return {
      hasChild: false,
      parentCodeList: [], //选择器明细项
      current: 1, //当前页码
      registerForm: {
        value: "",
        parentCodeKeyPath: "",
        codeKey: "",
        codeKeyPath: "",
      },
      /** 表单验证规则 */
      rules: {
        codeName: [
          { required: true, message: "名称不能为空", trigger: "blur" },
        ],
        codeKey: [
          { required: true, message: "唯一键不能为空", trigger: "blur" },
        ],
        codeKeyPath: [
          { required: true, message: "唯一标识不能为空", trigger: "blur" },
        ],
        value: [{ required: true, message: "值不能为空", trigger: "blur" }],
      },
    };
  },
  methods: {
    /** 保存事件 */
    whenSubmit() {
      if (this.readonly) return this.$emit("cancel");
      this.$refs.registerForm.validate((valid) => {
        if (valid) {
          if (this.registerForm.parentCodeId == "")
            this.registerForm.parentCodeId = null;
          if (!this.registerForm.id) {
            createCode(this.registerForm)
              .then(() => {
                this.$emit("submited");
                this.$emit("getCodes");
                this.$message.success("保存成功");
              })
              .catch(() => {
                this.$message.error("保存失败");
              });
          } else {
            updateCode(this.registerForm.id, this.registerForm)
              .then(() => {
                this.$emit("submited");
                this.$emit("handleTableChange");
                this.$message.success("保存成功");
              })
              .catch(() => {
                this.$message.error("保存失败");
              });
          }
        }
      });
    },
    /** 是否添加子项目复选框值变更事件 */
    onChange() {
      this.hasChild = !this.hasChild;
      this.registerForm.hasChild = this.hasChild;
    },
    /**
     * 选择器选中项事件
     * @param {string} value Select选中项值
     */
    whenChangeParent(value) {
      this.registerForm.parentCodeId = value;
      const parent = this.parentCodeList.find((x) => x.id === value) || {};
      this.registerForm.parentCodeKeyPath = parent.code || "";
      this.whenChangeCode();
    },
    whenChangeCode() {
      const parentCode = this.registerForm.parentCodeKeyPath || "";
      if (parentCode)
        this.registerForm.codeKeyPath = `${parentCode}_${this.registerForm.codeKey}`;
      else this.registerForm.codeKeyPath = `${this.registerForm.codeKey}`;
      this.$forceUpdate();
    },
    /**
     * 下拉框搜索事件
     * @param {string} value 搜索值
     */
    handleSearch(value) {
      getDowndownAsync({
        filter: value,
      }).then((res) => {
        this.parentCodeList = res.items;
      });
    },
  },
  watch: {
    form: {
      handler() {
        //Object.assign(this.registerForm, this.form);//this.form 直接传空更新不到this.registerForm，所以改成直接赋值
        this.registerForm = this.form;
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.checkbox {
  margin-left: 10px;
}
</style>
